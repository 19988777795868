$(function () {
    $('[type="checkbox"]').iCheck({checkboxClass: 'icheckbox_minimal'});

    var URL = "https://www.dialhost.com.br/";
    var DIALADMIN = "https://www.dialhost.com.br/dialadmin/";
    var DH = "https://www.dialhost.com.br/dh/";
    var HB_MODULE = "80";

    function hasStorage() {
        try {
            localStorage.setItem('test', 'ok');
            return true;
        } catch(e) {
            return false;
        }
    };

    if (hasStorage()) {
        $('#painel-user').val(localStorage.getItem('painel'));
    }

    $('#form-painel-de-controle').on('submit', function (e) {
        e.preventDefault();

        $('#form-painel-de-controle .alerta').slideUp();

        var user = $("#painel-user").val();
        var pass = $("#painel-pass").val();

        if (user == '' || pass == '')
        {
            $('#form-painel-de-controle .inputs').addClass('has-error');
        }
        else
        {
            $('#form-painel-de-controle .inputs').removeClass('has-error');
            $('body').css('cursor', 'progress');

            var lembrar = $('#painel-lembrar').prop('checked');
            var formulario = $('#form-painel-de-controle');
            var rl = false;

            var reg = /^[a-z0-9]+([_.-][a-z0-9]+)*\@[a-z0-9]+([.-][a-z0-9]+)*([.]([a-z0-9]{2,4}|[0-9]{1,3}))+$/i;

            if(user.match(reg)) {
                $.ajax({
                    type: 'POST',
                    url: DH + 'login/dh',
                    data: ({email: user , senha: pass}),
                    cache: false,
                    success: function (data) {
                        if (!data.success) {
                            $('#form-painel-de-controle .inputs').addClass('has-error');

                            $("#painel-pass").val('');
                            $("#painel-user")
                                .val('')
                                .focus();

                            $('#form-painel-de-controle .alerta').text('Usuário ou senha incorretos')
                                .hide()
                                .removeClass('hidden')
                                .slideDown();

                            $("body").css("cursor", "auto");
                            return false;
                        }

                        formulario.attr('action', DH + 'login');
                        $("#painel-user").attr('name','username');
                        $("#painel-pass").attr('name','password');
                        formulario.unbind('submit');
                        formulario.submit();
                    }
                });

                return false;
            }

            $.ajax({
                type: 'POST',
                url: DIALADMIN + 'publico/login',
                crossDomain: true,
                data: ({user: user , pass: pass, registra_log: rl, preservico: true}),
                cache: false,
                success: function (data) {
                    // Retorno: %SERVICOID%|%RESPOSTADEPENDENDODOSERVICO%
                    // O primeiro irá aparecer se acertar login, o segundo se acertar login e senha
                    var servico = data.split("|")[0];
                    data = data.split("|")[1];

                    // Para cada serviço, uma ação diferente
                    switch (servico)
                    {
                        case '1': 
                            // Hospedagem
                            // A resposta será [id]:[Status]:[Servidor]:[ServidorNoAr]:[SO]
                            var resposta = data.split(":");

                            // Condição para armazenar o usuário no localStorage
                            if (resposta.length > 1)
                            {
                                if (hasStorage() && lembrar)
                                {
                                    localStorage.setItem('painel', $("#painel-user").val());
                                }                               
                                else if (hasStorage())
                                {
                                    localStorage.setItem('painel', '');
                                }
                            }

                            if (resposta[4] && resposta[4] === 'W')
                            {
                                formulario.attr('action', "https://dialclient.dialhost.com.br/painel-de-controle/");
                                formulario.unbind('submit');
                                formulario.submit();
                            }                           
                            else if (resposta[1]=="A" && resposta[3] == 'S')
                            {
                                // Ação para LINUX
                                // Ativo e Servidor no ar, OK 
                                formulario.attr('action',"https://" + resposta[2] + ":2083/login/");
                                formulario.unbind('submit');
                                formulario.submit();
                            }
                            else if ((resposta[1]=="B" || resposta[1]=="C") || resposta[1]=="I" || resposta[1]=="P" || resposta[1]=="N" || resposta[1]=="E" || resposta[3] == 'N')
                            {  
                                //Bloqueado ou Cancelado ou Servidor fora do ar
                                formulario.attr('action', "https://dialclient.dialhost.com.br/painel-de-controle/");
                                formulario.unbind('submit');
                                formulario.submit();
                            }
                            else
                            {
                                $('#form-painel-de-controle .inputs').addClass('has-error');

                                if (data == "0")
                                {
                                    $("#painel-pass").val('');
                                    $("#painel-user")
                                        .val('')
                                        .focus();

                                    $('#form-painel-de-controle .alerta').text('Usuário ou senha incorretos')
                                        .hide()
                                        .removeClass('hidden')
                                        .slideDown();

                                    $("body").css("cursor", "auto");
                                }
                                else
                                {
                                    // Erro! 
                                    alert("Erro ao acessar o painel.\nEntre em contato com o nosso Atendimento. Erro: 300");
                                    $("body").css("cursor", "auto");
                                }
                            }
                        break;

                        case '3':
                            // DialMailer
                            // A resposta será [urlpost]::[keyhidden]

                            resposta = data.split("::"); // Por causa do http://

                            if (resposta[0] && resposta[1])
                            {
                                // Condição para armazenar o usuário no localStorage
                                if (resposta.length > 1)
                                {
                                    if ($("#lembrar-me-input").val() === 'S')
                                    {
                                        if (hasStorage())
                                        {
                                            localStorage.setItem('painel', $("#user").val());
                                        }
                                    }                               
                                    else 
                                    {
                                        if (hasStorage())
                                        {
                                            localStorage.setItem('painel', '');
                                        }
                                    }                               
                                }

                                // Trocando os atributos do form e logando...
                                formulario.attr('action', resposta[0]);
                                formulario.append("<input type=\"hidden\" name=\"dhk\" value=\"" + resposta[1] + "\">");
                                formulario.unbind('submit');

                                // envia o form para dialmailer via post
                                formulario.submit();
                            }
                            else
                            {
                                if (resposta == "0")
                                {
                                    //Login Inválido
                                    $("#painel-user")
                                        .val('')
                                        .focus();

                                    $("body").css("cursor", "auto");
                                }
                                else
                                {
                                    // Erro! 
                                    alert("Erro ao acessar o painel.\nEntre em contato com o nosso Atendimento. Erro: 300");
                                    $("body").css("cursor", "auto");
                                }
                            }
                        break;

                        case '4':
                            // DialiDC
                            // A resposta será [dialclient]::[1]

                            resposta = data.split("::"); // Por causa do http://

                            if (resposta[0] && resposta[1])
                            {
                                // Condição para armazenar o usuário no localStorage
                                if (resposta.length > 1)
                                {
                                    if ($("#lembrar-me-input").val() === 'S')
                                    {
                                        if (hasStorage())
                                        {
                                            localStorage.setItem('painel', $("#user").val());
                                        }
                                    }
                                    else
                                    {
                                        if (hasStorage())
                                        {
                                            localStorage.setItem('painel', '');
                                        }
                                    }                               
                                }

                                // Alterando opções do form
                                formulario.attr('action', resposta[0]);
                                formulario.append("<input type=\"hidden\" name=\"origem\" value=\"idc\">");
                                formulario.unbind('submit');

                                // envia o form
                                formulario.submit();
                            }
                            else
                            {
                                if (resposta == "0")
                                {
                                    $("#painel-user")
                                        .val('')
                                        .focus();

                                    $("body").css("cursor", "auto");
                                }
                                else
                                {  // Erro! 
                                    alert("Erro ao acessar o painel.\nEntre em contato com o nosso Atendimento. Erro: 300");
                                    $("body").css("cursor", "auto");
                                }
                            }
                        break;

                        default:
                            $('#form-painel-de-controle .inputs').addClass('has-error');
                            $("#painel-pass").val('');
                            $("#painel-user")
                                .val('')
                                .focus();

                            $('#form-painel-de-controle .alerta').text('Usuário ou senha incorretos')
                                .hide()
                                .removeClass('hidden')
                                .slideDown();

                            $("body").css("cursor", "auto");
                        break;
                    }
                }
            });
        }
    });
});
